import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useCustomNavigate } from '@/hooks/useCustomNavigate.ts'
import { Button, Icon, Typography } from '@/libs/common'
import { IconName } from '@/libs/enums'
import { handleSell } from '@/libs/helper'
import { formatTokenPrice } from '@/libs/helper/formatTokenPrice.tsx'
import { useModal } from '@/pages/modal-page/modal-page.tsx'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const ApproveSell = () => {
  const currentToken = useAppSelector((state) => state.chain.currentToken)
  const currentChain = useAppSelector((state) => state.chain.currentChain)

  const { setModalProps } = useModal()
  const location = useLocation()
  const navigate = useCustomNavigate()
  const { t } = useTranslation()

  const { tokenName, tokenSell, tokenReceive, ...payload } = location.state || {}

  useEffect(() => {
    setModalProps({ title: 'Confirm sell transaction' })
  }, [])

  const sell = async () => {
    await handleSell(payload)
    navigate({ isDashboard: true })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.icon}>
        <Icon name={IconName.APPROVE_BIG} />
      </div>
      <div className={styles.message}>
        <Typography variant="body2" textColor="color-grey-2">
          Are you sure you want to sell
        </Typography>
        <div className={styles.tokenInfo}>
          <Typography variant="body2" textColor="color-grey-3" className={styles.message}>
            {tokenSell ? formatTokenPrice(tokenSell).formatted : 0}{' '}
            {tokenName || currentToken?.token.name}
          </Typography>{' '}
          <Typography variant="body2" textColor="color-grey-2" className={styles.message}>
            for
          </Typography>{' '}
          <Typography variant="body2" textColor="color-grey-3" className={styles.message}>
            {tokenReceive ? formatTokenPrice(tokenReceive).formatted : 0} {currentChain.chainSymbol}
            .
          </Typography>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button styleVariant="black" onClick={() => navigate({ delta: -1 })}>
          <Typography variant="body2" textColor="white" className={styles.message}>
            {t('cancel')}
          </Typography>
        </Button>

        <Button styleVariant="yellow" onClick={sell}>
          <Typography variant="body2" textColor="black" className={styles.message}>
            {t('sell')}
          </Typography>
        </Button>
      </div>
    </div>
  )
}

export { ApproveSell }
